.order-form {
    width: 65%;
}

.order-summary {
    width: 35%;
    display: flex;
    flex-direction: column;
}

.order-summary > img {
    width: 100%;
    border-radius: 1em 1em 0 0;
}

.two-columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2em;
}

.button-pay {
    background-color: var(--green);
    border-radius: 0.5em;
    border: none;
    width: 100%;
    font-weight: 900;
    color: var(--blue);
    padding: 1em;
}

.product, .payment-method {
    border: 1px solid lightgray;
    border-radius: 0.25em;
    margin-top: 1em;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.product-amount {
    font-size: 1.5rem;
    gap: 0.5em;
    display: flex;
}

.payment-method > i, .payment-method > img {
    padding: 0.5em;
    font-size: 2.5em;
    height: 1em;
}

.payment-method > i:hover, .payment-method > img:hover {
    background-color: lightgrey;
    border-radius: 0.25em;
}

form {
    gap: 0.5em;
    display: flex;
    flex-direction: column;
}

.order-summary-confirmation {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0.5em;
    background-color: lightgray;
    margin-bottom: 1em;
    border-radius: 0 0 0.5em 0.5em;
}


.order-item, .order-total {
    display: flex;
    justify-content: space-between;
}

.order-total {
    font-weight: 900;
}

a > i, span > p > a {
    color: black;
}

.MuiFormControlLabel-root > div {
    display: flex;
}

label > span, .MuiFormControlLabel-root > div > span, .agreement {
    font-size: 0.65rem !important;
}

.MuiFormControlLabel-root > div > .MuiFormControlLabel-asterisk {
    order: -1;
    color: red;
    font-size: 1rem !important;
}

@media (max-width: 768px) {
    .order-form {
        width: 100%;
    }
    
    .order-summary {
        width: 100%;
    }   

    .two-columns {
        flex-direction: column;
    }
}