* {
    margin: 0;
}

:root {
    --light-green: #f3f7eb;
    --blue: #05396b;
    --light-blue: #07539c;
    --grey: #ededed;
    --green: #7ce187;
    --gradient-green: #6dd578;
}

body {
    margin-bottom: 100px;
    min-height: calc(100vh - 80px - 100px);
    background-color: var(--green);
    font-family: "Josefin Sans", sans-serif;
}

h1 {
    color: #05396b;
}

h2 {
    font-size: 2.5rem;
}

h2 > span {
    color: #05396b;
}

img.background {
    position: absolute;
    z-index: -1;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
}


/* FORMS */


form>input,
form>textarea {
    width: 80%;
    width: 80%;
    border-radius: 0.5rem;
    color: #05396b;
    padding: 0.25em;
    background-color: #ffffffe3;
    border: unset;
    padding-left: 1rem;
}

form>input:focus,
form>textarea:focus,
form>div>input:focus,
form>div>textarea:focus {
    outline: none !important;
}


form>textarea,
form>div>textarea {
    height: 8rem;
}


/* CURSOR */


.trail {
    /* className for the trail elements */
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background: var(--blue);
    pointer-events: none;
}

.pin2 {
    pointer-events: none;
    position: absolute;
    border-radius: 50%;
    border: 8px solid #fff;
    width: 8px;
    height: 8px;
    border-color: var(--blue);
    transform: translate(-10px, -30px);
}

.pin2::after {
    pointer-events: none;
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid var(--blue);
}