.tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2em 0;
    gap: 2em;
}


.tags>span {
    color: white;
    border-radius: 2em;

    padding: 0.5em 1em;
    font-size: 1.5em;
    background-color: var(--blue);
}

.button-mail {
    margin-top: 1.5em;
}