.product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 300px;
    margin-bottom: 2em;
}

.product-image {
    height: 100%;
}

.product-image > img {
    height: inherit;
    border-radius: 1em;
}

.product-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.product-text {
    white-space: pre-line;
}

.product-price {
    background-color: var(--green);
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: fit-content;

    display: flex;
    gap: 1em;
    text-decoration: none;
    color: black;
}

.right {
    place-self: flex-end;
    width: fit-content;
}

.product-title {
    color: var(--blue);
}

@media only screen and (max-width: 1000px) {
    .product-container {
        height: auto;
        flex-direction: column;
    }

    .product-image > img {
        width: 100%;
    }
}