nav {
    background-color: var(--light-green);
    padding: 1em 2em;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav > a > img {
    height: 55px;
}

nav > a {
    font-size: 0;
}

select {
    border: 2px solid var(--blue);
    box-sizing: border-box;
    border-radius: 0.6rem;
    align-self: center;
    text-align: center;
    color: var(--blue);
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.25em;
    background-color: transparent;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

select:focus {
    outline: none !important;
}



@media (max-width: 600px) {
    a > div > button.button {
        display: none;
    }
}

.hamburger-icon {
    display: none; /* Hidden by default */
    cursor: pointer;
}

.menu-options > a {
    text-decoration: none;
    color: black;
}
.menu-options {
    display: flex;
    align-items: center;
}

.dot-separated-list a:not(:last-child)::after {
    content: '•';
    margin: 0 8px;
    color: #000;
}
/* Mobile styles */
@media (max-width: 768px) {
    .menu-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 20px;
        background-color: white;
        width: 200px;
        /*border: 1px solid #ccc; !* Optional border *!*/
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .menu-options.hidden {
        display: none;
    }

    .menu-options.visible {
        display: flex;
        z-index: 1;
        justify-content: center;
        align-items: flex-end;
        text-align: right;
        gap: 1rem;
        border-radius: 0.25em;
        width: auto;
        margin-top: 1em;
    }

    .menu-options.visible > select {
        align-self: end;
    }

    .hamburger-icon {
        display: block;
    }
}
