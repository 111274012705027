.actions {
    display: flex;
    justify-content: center;
    gap: 1em;
    padding: 2em auto;
    min-height: 50vh;
}

.actions > button {
    padding: 1em 3em;
    background-color: var(--light-green);;
    border-radius: 1em;
    color: var(--blue);
    font-weight: 600;
    border: none;
    height: fit-content;
}

.thank-you-page {
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: center;
    align-items: center;
}