.app-card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 1.25em;
    width: 250px;
}

.app-card > img {
    width: 250px;
    height: 220px;
    aspect-ratio: 1;
    object-fit: cover;
    margin: 0;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: block; /* Added this line */
}

.app-card > p {
    background-color: var(--blue);
    color: white;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    margin: 0;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}