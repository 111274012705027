main {
    height: calc(100vh - 55px - 2em - 4em);
    margin-bottom: 6em;
    display: flex;
}

.row {
    padding-left: 15%;
    padding-right: 15%;
}

section {
    min-height: 200px;
    background: var(--light-green);
    border-radius: 1em;
    padding: 2em 2em;
    color: var(--blue);

    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.flex-column-center-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-wrapper>h1,
.screenshot-wrapper>h1 {
    line-height: 1.2em;
    font-size: 3em;
    text-align: center;
    padding: 1em;
}

.sub-main-wrapper {
    display: grid;
    grid-template-columns: 3fr 2fr;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2em;
}

.main-wrapper {
    margin: 0;
    background-color: var(--light-green);
}

.main-title {
    line-height: 1.2em;
}

h1>span {
    color: var(--blue);
}

.main-wrapper>h1,
.screenshot-wrapper>h1 {
    line-height: 1.2em;
    font-size: 3em;
    text-align: center;
    padding: 1em;
}

.sub-main-wrapper {
    display: grid;
    grid-template-columns: 3fr 2fr;
    justify-content: center;
    align-items: center;
}

.main-description {
    font-size: 1.25em;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    gap: 1.25em;
}

.main-mockup>img {
    z-index: 1;
    max-height: 80vh;
}

.element-background {
    width: 500px;
    height: 500px;
    position: absolute;
    background: linear-gradient(45deg, var(--green) 0%, var(--gradient-green) 50%, var(--green) 100%);
    background-size: 200% 200%;
    background-position: left bottom;
    filter: drop-shadow(0 15px 20px #bdbdbd);
    transform: rotateZ(3deg);
    border-radius: 1em;
}

.animation-rotation-gradient {
    animation-name: rotation-gradient;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.main-description>svg {
    height: 60px;
}

@keyframes rotation-gradient {
    0% {
        background-position: left bottom;
        transform: rotateZ(-1deg);
    }

    50% {
        background-position: right top;
        transform: rotateZ(4deg);
    }

    100% {
        background-position: left bottom;
        transform: rotateZ(-1deg);
    }
}

/* OLD BELOW */
div#root section:nth-of-type(1) {
    padding: 2em;
}

div#root section:nth-of-type(1)>div>h3 {
    font-size: large;
}

section>div>h3,
iframe,
section {
    margin-bottom: 2em;
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.swiper-slide>img,
.swiper-slide>svg {
    height: 2rem;
}

section#contact,
section#success {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

section#success>div {
    width: 100%;
}

section#offert {
    display: flex;
    flex-direction: row;
    padding: 5em 2em;
    margin-top: 1em;
}

section#offert>div, section#offert>p {
    width: 50%;
}

section#offert>div>img {
    width: 100%;
}

section#offert>p {
    font-size: 1.15em;
}
#successes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4em;
}

.technology-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.technology-chip {
    border-radius: 1em;
    background-color: white;
    line-height: 2em;
    color: black;
    padding: 0 1em;
    width: fit-content;
    border-radius: 1em;
}

.project-presentation {
    width: calc(50% - 2em);
    display: flex;
    flex-direction: column;
    gap: 2em;
}


.success {
    display: flex;
    width: 100%;
}

.success-item {
    width: calc(100% / 6);
}

.success-item>img {
    width: 100%;
}

.swiper-wrapper {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: stretch;
}

.swiper-slide {
    gap: 1em;
    text-align: center;
}

.project {
    display: flex;
    width: calc(100% - 2em);
    gap: 2em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.project>img {
    width: 50%;
}

@media (max-width: 600px) {
    div#root section:nth-of-type(1) {
        flex-direction: column;
    }

    div#root section:nth-of-type(1)>div {
        width: 100%;
    }

}

@media only screen and (max-width: 1000px) {
    .main-description>img {
        height: 60px;
        width: 100%;
        margin: 2em 0;
    }
}

.main-description>img {
    height: 40px;
}

.android {
    width: 150px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 0.5em;
    flex-wrap: wrap;
}


.button {
    text-align: center;
    text-decoration: none;
    color: white;
    border-radius: 2em;
    width: fit-content;
    filter: drop-shadow(0 1px 3px #bdbdbd);
}

.button-main {
    background: linear-gradient(45deg, var(--blue) 0%, var(--blue) 50%, var(--blue) 100%);
    transition: background-color .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    height: 48px;
    width: 48px;
}

.button-blue {
    padding: 0.5em 1em;
    font-size: 1.5em;
    border: none;
    background-color: var(--blue);
}

.button-main:hover {
    background: linear-gradient(45deg, var(--light-blue) 0%, var(--light-blue) 50%, var(--light-blue) 100%);
}

.button-secondary img,
.button-tertiary img {
    transition: filter .2s;
    height: 48px;
}

.button-secondary img:hover {
    filter: invert(81%) sepia(11%) saturate(1606%) hue-rotate(74deg) brightness(96%) contrast(93%);
}

.button-tertiary img:hover {
    filter: invert(81%) sepia(11%) saturate(1606%) hue-rotate(74deg) brightness(96%) contrast(93%);
}

section.how-it-works {
    background-color: var(--blue);
    color: white;
}

.white-background, .green-background {
    background-color: white;
    padding-top: 2em;
    padding-bottom: 2em;
    margin: 2em auto;
}

.container {
    margin-left: 15%;
    margin-right: 15%;
}

.how-it-works {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.how-it-works>.steps {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.steps>div {
    width: calc(100% / 3 - 4em);
    border-radius: 0.5em;

    min-height: 17em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.steps>div>span {
    padding: 1em;
    background-color: var(--green);
    color: var(--blue);
    border-radius: 2em;
    font-weight: bold;
    text-align: center;
}

.steps > div > img {
    padding: 0.25em;
    border-radius: 0.5em;
    aspect-ratio: 1;
}

/* PRICING */

.pricing {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 4em;
}

.pricing>.price-box {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: calc(100% / 3);
    max-width: 350px;

}

.pricing>.price-box:hover {
    transform: scale(1.05);
}
.pricing>.price-box>div,
.pricing>.price-box>p {
    border-radius: 1em;
    padding: 1em;

}

.pricing>.price-box>div {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: var(--green);
    text-align: left;
    line-height: 1.25em;
}

.pricing>.price-box>p {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    min-height: 300px;
    background-color: var(--light-green);
}
.blue-background {
    background-color: var(--blue) !important;
    color: white
}

@media only screen and (max-width: 1400px) {
    .main-mockup>img {
        max-height: 60vh;
    }
    .element-background {
        width: 400px;
        height: 400px;
    }
}

@media only screen and (max-width: 1200px) {
    .main-mockup>img {
        max-height: 50vh;
    }
    .element-background {
        width: 300px;
        height: 300px;
    }
}

@media only screen and (max-width: 1000px) {
    .main-mockup>img {
        max-height: 70vh;
    }
    .element-background {
        width: 60vw;
        height: 60vw;
    }

    .row {
        padding-left: 10%;
        padding-right: 10%;
    }

    section#offert {
        flex-direction: column;
    }
    
    section#offert>div, section#offert>p {
        width: 100%;
    }

    section#offert>div>center>button {
        max-width: 100%;
        font-size: 1.1em;
    }
}


@media only screen and (max-width: 1000px) {
    main {
        height: auto;
    }

    .main-wrapper {
        height: auto;
    }

    .sub-main-wrapper {
        grid-template-columns: 1fr;
    }

    .main-description {
        font-size: 1em;
        padding: 1em;
    }

    .main-mockup>img {
        max-width: 75vw;
    }

    .main-description>svg {
        height: 60px;
        width: 100%;
        margin: 2em 0;
    }

    .textIcon {
        margin: 1em;
    }

    section#offert {
        grid-template-columns: 1fr;
    }

    .steps > div {
        width: calc(100% / 2 - 2em);
    }
}

@media only screen and (max-width: 570px) {
    .row {
        padding-left: 10%;
        padding-right: 10%;
    }

    .buttons {
        justify-content: center;
    }

    .container {
        margin-left: 5%;
        margin-right: 5%;
    }

    nav {
        margin: 0;
        width: 100%;
    }

    .pricing>.price-box {
        width: 100%
    }

    .steps > div {
        width: 100%;
    }
    .steps > div > img {
        padding: 0.25em;
        aspect-ratio: 1;
    }

    .main-wrapper {
        padding: 0 !important;
    }
}